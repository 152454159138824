<template>
  <input
    type="text"
    autocomplete="one-time-code"
    :value="value"
    :inputmode="inputmode"
    :maxlength="maxlength"
    placeholder="1234"
  />
</template>
<script>
export default {
  name: "OtpInput",
  props: {
    value: {
      type: String,
    },
    inputmode: {
      type: String,
      default: "numeric",
    },
    maxlength: {
      type: Number,
      default: 4,
    },
  },
};
</script>

<style scoped>
input[type=text] {
  transition: width 0.4s ease-in-out;
  width: 8rem;
  padding: 0rem 1rem;
  font-size: 3rem;
  text-align: center;
  text-decoration: underline;
}
input[type=text]::placeholder {
  color: rgba(0.0, 0.0, 0.0, 0.2);
}
</style>