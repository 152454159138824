<template>
  <div id="app">
    <h1>OTP Input demo</h1>
    <otp-input v-model="otp"></otp-input>
  </div>
</template>

<script>
import OtpInput from "./components/OtpInput.vue";

export default {
  name: "App",
  components: {
    OtpInput,
  },

  data() {
    return {
      otp: "",
    };
  },

  async mounted() {
    if('OTPCredential' in window) {
      const otp = await navigator.credentials.get({otp: {transport: ['sms']}});
      return otp.code;
    }
  },
};
</script>

<style>
#app {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
