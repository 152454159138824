import { render, staticRenderFns } from "./OtpInput.vue?vue&type=template&id=af44ffa4&scoped=true&"
import script from "./OtpInput.vue?vue&type=script&lang=js&"
export * from "./OtpInput.vue?vue&type=script&lang=js&"
import style0 from "./OtpInput.vue?vue&type=style&index=0&id=af44ffa4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af44ffa4",
  null
  
)

export default component.exports